import Icon from '@/components/common/Icon'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import { DropDownPropsType } from '@/hooks/common/useDropDown'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import isEmpty from 'lodash/isEmpty'
import { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react'

export enum DropDownDirectionEnum {
  Up = 'Up',
  Down = 'Down'
}

type DropDownProps<TData> = {
  defaultItemText: string
  dropDownProps: DropDownPropsType<TData>
  direction?: DropDownDirectionEnum
  disabled?: boolean
  isResetSelection?: boolean
}

/**
 * 디자인 시스템을 따르는 DropDown 입니다.
 * @link: https://www.figma.com/file/bnWIiHfgHgV5QyiVP5ek8t/Design-System?node-id=125-1075&t=6kw3ARvFp25quxV3-0
 *
 * @param defaultItemText 기본 아이템 텍스트 입니다.
 * @param dropDownProps 드롭다운 props
 * @param direction 드롭다운의 방향입니다.
 * @param disabled 드롭다운 활성화 여부를 결정합니다
 * @param isResetSelection 아이템 선택하지 않을시, 미선택 상태로 되돌릴지 여부를 결정합니다
 * @param children
 */

const DropDown = <TData = unknown,>({
  defaultItemText,
  dropDownProps,
  direction = DropDownDirectionEnum.Up,
  disabled = false,
  isResetSelection = true,
  children
}: PropsWithChildren<DropDownProps<TData>>) => {
  const { mount, isOpen, controls, selectedItem, handleOpen, handleClose, setSelectedItem } = dropDownProps
  const isReverse = direction === DropDownDirectionEnum.Down
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const handleClickDefaultItem = () => {
    if (disabled) {
      return
    }
    if (isOpen) {
      if (isResetSelection) {
        setSelectedItem(undefined)
      }
      handleClose()
    } else {
      handleOpen()
    }
  }

  // todo: document 객체에 event listener를 붙이지 않고 외부 클릭을 감지할 방법 찾아내어 적용하기
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        handleClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="relative h-54 w-full cursor-pointer" ref={dropdownRef}>
      {mount && (
        <motion.div
          animate={controls}
          initial="hidden"
          transition={{
            damping: 40,
            stiffness: 600,
            type: 'spring'
          }}
          variants={{
            hidden: { opacity: 0, y: 0 },
            visible: { opacity: 1, y: direction === DropDownDirectionEnum.Up ? -54 : 54 }
          }}
          className={classNames(
            'absolute z-[100] flex w-full flex-col overflow-hidden border-1 border-gray-800 bg-white',
            {
              'bottom-0 rounded-t-xs border-b-0': !isReverse
            },
            {
              'top-0 rounded-b-xs border-t-0': isReverse
            }
          )}>
          {children}
        </motion.div>
      )}
      <div
        className={classNames(
          `flex h-54 w-full items-center justify-between rounded-xs border-1 ${
            disabled ? 'border-gray-100' : 'border-gray-200'
          } bg-white px-12 py-12`,
          {
            'rounded-t-0 border-gray-800 border-t-gray-200': isOpen && !isReverse
          },
          {
            'rounded-b-0 border-gray-800 border-b-gray-200': isOpen && isReverse
          }
        )}
        onClick={handleClickDefaultItem}>
        <span
          className={classNames(`body2 ${disabled ? 'text-gray-200' : 'text-gray-500'}`, {
            'font-bold text-gray-800': selectedItem && !isOpen
          })}>
          {selectedItem ? (isOpen ? defaultItemText : selectedItem.content) : defaultItemText}
        </span>
        <Icon
          name={isOpen ? IconNamesEnum.ChevronUp : IconNamesEnum.ChevronDown}
          className={`h-24 w-24 ${disabled ? 'text-gray-200' : 'text-gray-500'}`}
        />
      </div>
    </div>
  )
}

type DropDownSearchProps<TData> = {
  dropDownProps: DropDownPropsType<TData>
  searchPlaceHolder: string
}
const DropDownSearch = <TData = unknown,>({ dropDownProps, searchPlaceHolder }: DropDownSearchProps<TData>) => {
  const { searchText, setSearchText } = dropDownProps
  const [searchInput] = useState<string | undefined>(searchText)
  const inputTextRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    return () => {
      setSearchText(undefined)
    }
  }, [])

  return (
    <div className="flex w-full cursor-default items-center justify-between px-12 py-12">
      <div
        className={classNames(
          'flex w-full items-center justify-between rounded-xs bg-gray-50 px-12 py-8 text-gray-300',
          {
            'text-gray-800': !isEmpty(searchText)
          }
        )}>
        <div className="flex gap-4">
          <Icon name={IconNamesEnum.Search} className="h-20 w-20" />
          <input
            type="text"
            className="body2 w-240 bg-gray-50 outline-none outline-0"
            inputMode="search"
            placeholder={searchPlaceHolder}
            defaultValue={searchInput}
            value={searchInput}
            ref={inputTextRef}
            onClick={(e) => {
              e.preventDefault()
              if (searchText === searchPlaceHolder) {
                setSearchText(undefined)
              }
            }}
            onChange={(e) => {
              e.preventDefault()
              setSearchText(e.target.value)
            }}
          />
        </div>
        {!isEmpty(searchText) && (
          <button
            className="flex h-full w-fit items-center"
            onClick={() => {
              setSearchText(undefined)
              if (!inputTextRef.current) {
                return
              }
              inputTextRef.current.focus({ preventScroll: true })
              inputTextRef.current.value = ''
            }}>
            <Icon name={IconNamesEnum.Delete} className="h-20 w-20 text-gray-300" />
          </button>
        )}
      </div>
    </div>
  )
}

type DropDownItemProps<TData> = {
  dropDownProps: DropDownPropsType<TData>
  itemContent: {
    id: string
    content: ReactNode
  }
}

const DropDownItem = <TData = unknown,>({ dropDownProps, itemContent }: DropDownItemProps<TData>) => {
  const { setSelectedItem, selectedItem, handleClose, optionList } = dropDownProps

  return (
    <div
      className={classNames(
        'body2 flex w-full items-center justify-between px-12 py-12 text-gray-500 hover:bg-gray-50 hover:font-bold hover:text-gray-800',
        {
          'bg-gray-100 font-bold text-gray-800': selectedItem?.id === itemContent.id
        }
      )}
      onClick={() => {
        const targetItem = optionList?.find((option) => option.id === itemContent.id)
        if (targetItem) {
          setSelectedItem(targetItem)
        }
        handleClose()
      }}>
      {itemContent.content}
    </div>
  )
}

const DropDownList = ({ children }: PropsWithChildren) => {
  return <ul className="flex h-fit max-h-220 w-full flex-col overflow-y-scroll ">{children}</ul>
}

export const searchTextHighLight = (text: string, searchText?: string) => {
  if (!searchText) {
    return <span>{text}</span>
  }

  const textArr = text
    .replaceAll(searchText, '\n' + searchText + '\n')
    .split('\n')
    .filter((word) => !isEmpty(word))

  return (
    <div className="flex">
      {textArr.map((word, index) => {
        if (word === searchText) {
          return (
            <span key={index} className="text-primary">
              {word}
            </span>
          )
        } else {
          return <span key={index}>{word}</span>
        }
      })}
    </div>
  )
}

DropDown.Search = DropDownSearch
DropDown.Item = DropDownItem
DropDown.List = DropDownList
export default DropDown
