import { AnimationControls, useAnimation } from 'framer-motion'
import isEmpty from 'lodash/isEmpty'
import { Dispatch, SetStateAction, useState } from 'react'

export type DropDownPropsType<TData = unknown> = {
  handleOpen(): void
  handleClose(): void
  mount: boolean
  isOpen: boolean
  controls: AnimationControls
  selectedItem?: DropDownContentType<TData>
  setSelectedItem(item: DropDownContentType<TData> | undefined): void
  optionList?: DropDownContentType<TData>[]
  setOptionList: Dispatch<SetStateAction<DropDownContentType<TData>[] | undefined>>
  searchText?: string
  setSearchText: Dispatch<SetStateAction<string | undefined>>
  searchFilterList?: DropDownContentType<TData>[]
}

export type DropDownContentType<TData = unknown> = {
  id: string
  content: string
  data?: TData
}

interface UseDropDownProps<TData> {
  onChange?(selectedItem: DropDownContentType<TData> | undefined): void
}

const useDropDown = <TData = unknown>(props?: UseDropDownProps<TData>): DropDownPropsType<TData> => {
  const { onChange } = props ?? {}
  const [mount, setMount] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const controls = useAnimation()
  const [selectedItem, _setSelectedItem] = useState<DropDownContentType<TData>>()
  const setSelectedItem = (item: DropDownContentType<TData> | undefined) => {
    _setSelectedItem(item)
    onChange?.(item)
  }
  const [searchText, setSearchText] = useState<string>()
  const [optionList, setOptionList] = useState<DropDownContentType<TData>[]>()

  const handleOpen = () => {
    setMount(true)
    setTimeout(() => {
      setIsOpen(true)
      controls.start('visible')
    }, 0)
  }

  const handleClose = () => {
    controls.start('hidden')
    setIsOpen(false)

    setTimeout(() => {
      setMount(false)
    }, 300)
  }

  const getSearchFilterList = () => {
    if (isEmpty(searchText) || !searchText) {
      return optionList
    }
    return optionList?.filter((option) => option.content.includes(searchText))
  }

  return {
    handleOpen,
    handleClose,
    mount,
    isOpen,
    selectedItem,
    setSelectedItem,
    optionList,
    setOptionList,
    searchText,
    setSearchText,
    controls,
    searchFilterList: getSearchFilterList()
  }
}

export default useDropDown
